import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import LockResetIcon from '@mui/icons-material/LockReset';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import DialogoAgregar from './Clientes/DialogoAgregar'
import DialogoEditar from './Clientes/DialogoEditar'
import DialogoEliminar from './Clientes/DialogoEliminar'
import DialogoResetPassword from './Clientes/DialogoResetPassword'

const headCells = [
  {
    id: 'acciones',
    label: '',
    width: '7.14%',
  },
  {
    id: 'nombres',
    label: 'Nombres(s)',
    width: '7.14%',
  },
  {
    id: 'paterno',
    label: 'A. Paterno',
    width: '7.14%',
  },
  {
    id: 'materno',
    label: 'A. Materno',
    width: '7.14%',
  },
  {
    id: 'usuario',
    label: 'Usuario',
    width: '7.14%',
  },
  {
    id: 'password',
    label: 'Password',
    width: '7.14%',
  },
  {
    id: 'estado',
    label: 'Estado',
    width: '7.14%',
  },
  {
    id: 'sexo',
    label: 'Sexo',
    width: '7.14%',
  },
  {
    id: 'celular',
    label: '# Celular',
    width: '7.14%',
  },
  {
    id: 'mail',
    label: 'E-Mail',
    width: '7.14%',
  },
  {
    id: 'pago_fecha',
    label: 'Fecha pago',
    width: '7.14%',
  },
  {
    id: 'pago_monto',
    label: 'Pago monto',
    width: '7.14%',
  },
  {
    id: 'intentos',
    label: 'Intentos de conexión',
    width: '7.14%',
  },
  {
    id: 'ult_con',
    label: 'Ultima conexión',
    width: '7.14%',
  }
];

export default function Clientes(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [buscar, setBuscar] = React.useState('');
  const [clientes, setClientes] = React.useState([]); 
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [dialogoAgregar, setDialogoAgregar] = React.useState(false);
  const [dialogoEditar, setDialogoEditar] = React.useState(false);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);
  const [dialogoResetPassword, setDialogoResetPassword] = React.useState(false);
  const [msgVacio, setMsgVacio] = React.useState(false);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      } 
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("5")) {                    
        navigate('/');
      }
    }    
    props.setPagina('clientes'); cookies.set('sethmon_pagina', 'clientes');
  }, []);

  useEffect(() => {    
    getDatos();
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/clientes/get_datos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setClientes(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  function getDatos2(u){
    axios.post(props.ruta + "backend/clientes/get_datos_afectados.php",{
      userId: props.userArray.id,
      usuario: u
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setClientes(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  function busqueda(){
    axios.post(props.ruta + "backend/clientes/get_buscar.php",{
      userId: props.userArray.id,
      buscar: buscar
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setClientes(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  const renderBotonAgregar = () => {
    if (props.checkPermisos("1") || props.checkPermisos("8")) {      
      return(
        <Grid xs={1} md={2}>  
          <Button variant="contained" startIcon={<AddIcon />} style={{backgroundColor: '#082342', color: '#ffffff'}} onClick={() => setDialogoAgregar(true)}>
            Agregar
          </Button>
        </Grid>
      )
    }
  }
  
  const renderDialogoAgregar = () =>{    
    if(dialogoAgregar === true){
      return(
        <DialogoAgregar 
          {...props}     
          open = {dialogoAgregar}
          setOpen = {setDialogoAgregar}
          getDatos2 = {getDatos2}
        />
      )
    }
  }

  const renderBotonEditar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("9")) {      
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
          <EditIcon />
        </IconButton>
      )
    }
  }

  function editar(i){
    setArrayDatos(i);
    setDialogoEditar(true);
  }
  
  const renderDialogoEditar = () =>{    
    if(dialogoEditar === true){
      return(
        <DialogoEditar
          {...props}     
          open = {dialogoEditar}
          setBuscar = {setBuscar}
          setOpen = {setDialogoEditar}
          getDatos2 = {getDatos2}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonEliminar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("10")) {
      return(         
        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonResetPassword = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("11")) {
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => reset(datos)}>
          <LockResetIcon />
        </IconButton>
      )
    }
  }

  function reset(i){
    setArrayDatos(i);
    setDialogoResetPassword(true);
  }
  
  const renderDialogoResetPassword = () =>{    
    if(dialogoResetPassword === true){
      return(
        <DialogoResetPassword
          {...props}     
          open = {dialogoResetPassword}
          setOpen = {setDialogoResetPassword}
          arrayDatos = {arrayDatos}
        />
      )
    }
  } 

  const renderTableBody = () =>{    
    if(msgVacio === false){
      return(
        <TableBody>
          {clientes.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{cursor: 'default', backgroundColor: row.estado === 'B'? '#ffcdd2':'' }}
              >
                <TableCell  component="th" scope="row" padding="none" align='center'>
                  {renderBotonEditar(row)}
                  {renderBotonEliminar(row)}
                </TableCell>
                <TableCell align="center">{row.nombre}</TableCell>
                <TableCell align="center">{row.aPaterno}</TableCell>
                <TableCell align="center">{row.aMaterno}</TableCell>
                <TableCell align="center">{row.usuario}</TableCell>
                <TableCell align="center">
                  {renderBotonResetPassword(row)}
                </TableCell>
                <TableCell align="center">{row.estadoFormato}</TableCell>
                <TableCell align="center">{row.sexo}</TableCell>
                <TableCell align="center">{row.celular}</TableCell>
                <TableCell align="center">{row.mail}</TableCell>
                <TableCell align="center">{row.pagoFechaFormato}</TableCell>
                <TableCell align="center">{row.pagoMontoFormato}</TableCell>
                <TableCell align="center">{row.intentos}</TableCell>
                <TableCell align="center">{row.ultCon}</TableCell>
              </TableRow>
            );
          })}                  
        </TableBody>
      )
    } else {
      return(         
        <TableRow
          hover
          tabIndex={-1}
          key={1}
          style={{cursor: 'default'}}
        >
          <TableCell align="center" colSpan={14}>No se tienen registros</TableCell>
        </TableRow>
      )
    }
  } 
  
  return (
    <Box sx={{ minHeight: '100vh'}} style={{backgroundColor: props.colorTres}} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#ffffff', textAlign: 'center'}}>
          Clientes
          </Typography>
        </Grid>

        {renderBotonAgregar()}
        <Grid xs={4}>  
          <TextField id="buscar" label="Buscar" variant="outlined" style={{backgroundColor: '#ffffff', width:'100%'}}               
            value={buscar}
            onChange={(event) => setBuscar(event.target.value)}
          />
        </Grid>
        <Grid xs={7} md={6}>  
          <Button variant="contained" startIcon={<SearchIcon />} style={{backgroundColor: '#082342', color: '#ffffff'}} onClick={() => busqueda()}/>
        </Grid>

        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {renderTableBody()}                
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>    
      </Grid>
      
      {renderDialogoAgregar()}
      {renderDialogoEditar()}
      {renderDialogoEliminar()}
      {renderDialogoResetPassword()}

    </Box>
  );
}