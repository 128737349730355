import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');
  const [app, setApp] = React.useState('');
  const [appError, setAppError] = React.useState('');
  const [apm, setApm] = React.useState('');
  const [apmError, setApmError] = React.useState('');
  const [usuario, setUsuario] = React.useState('');
  const [usuarioError, setUsuarioError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [estado, setEstado] = React.useState('A');
  const [sexo, setSexo] = React.useState('M');
  const [celular, setCelular] = React.useState('');
  const [celularError, setCelularError] = React.useState('');
  const [mail, setMail] = React.useState('');
  const [mailError, setMailError] = React.useState('');
  const [costo, setCosto] = React.useState('');
  const [costoError, setCostoError] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  const handleClickShowPassword = () => {
    if(showPassword === false){
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (nombre === "") { setNombreError('error'); ban = 1; } else { setNombreError(''); }
    if (app === "") { setAppError('error'); ban = 1; } else { setAppError(''); }
    if (apm === "") { setApmError('error'); ban = 1; } else { setApmError(''); }
    if (usuario === "") { setUsuarioError('error'); ban = 1; } else { setUsuarioError(''); }
    if (password === "") { setPasswordError('error'); ban = 1; } else { setPasswordError(''); }
    if (estado === "") { ban = 1; }
    if (sexo === "") { ban = 1; }
    if (celular === "") { setCelularError('error'); ban = 1; } else { setCelularError(''); }
    if (mail === "") { setMailError('error'); ban = 1; } else { setMailError(''); }    
    if (mail === "") { setMailError('error'); ban = 1; } else { setMailError(''); } 
    if (costo === "") { setCostoError('error'); ban = 1; } else { setCostoError(''); }

    if(ban === 0){
      let resultado = parseFloat(costo);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - costo;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setCostoError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setCostoError('error');
        ban = 2;
      }

      if(Number.isNaN(resultado) && resultado !== costo){   
        console.log(!Number.isNaN(resultado));
      }
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/usuarios/agregar.php", {
          userId: props.userArray.id,
          nombre: nombre,
          app: app,
          apm: apm,
          usuario: usuario,
          password: password,
          estado: estado,
          sexo: sexo,
          celular: celular,
          mail: mail
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.getDatos();
            props.setOpen(false);
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en los campos de "Costo patronal" y "Costo libre"');
    } else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('El usuario ya existe');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='lg'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Agregar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField id="nombre" label="Nombre" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="app" label="A. Paterno" variant="outlined" style={{width:'100%'}}               
                value={app}
                onChange={(event) => setApp(event.target.value)}
                error={appError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="apm" label="A. Materno" variant="outlined" style={{width:'100%'}}               
                value={apm}
                onChange={(event) => setApm(event.target.value)}
                error={apmError}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField id="usuario" label="Usuario" variant="outlined" style={{width:'100%'}}               
                value={usuario}
                onChange={(event) => setUsuario(event.target.value)}
                error={usuarioError}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl
                variant="outlined"
                style={{backgroundColor: '#ffffff', width:'100%'}}            
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  error={passwordError}
                  value={password}
                  label='Contraseña'
                  style={{width:'100%'}}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={100}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Estado"
                value={estado}
                style={{width: '100%'}}
                onChange={(event) => setEstado(event.target.value)}
              >
                <MenuItem key={0} value='B'>
                  Baja
                </MenuItem>
                <MenuItem key={1} value='A'>
                  Activo
                </MenuItem>
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Sexo"
                value={sexo}
                style={{width: '100%'}}
                onChange={(event) => setSexo(event.target.value)}
              >
                <MenuItem key={0} value='M'>
                  Masculino
                </MenuItem>
                <MenuItem key={1} value='F'>
                  Femenino
                </MenuItem>
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField id="celular" label="# Celular" variant="outlined" style={{width:'100%'}}               
                value={celular}
                onChange={(event) => setCelular(event.target.value)}
                error={celularError}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField id="mail" label="E-Mail" variant="outlined" style={{width:'100%'}}               
                value={mail}
                onChange={(event) => setMail(event.target.value)}
                error={mailError}
              />
            </Grid>
            
            <Grid item xs={12} md={4}>
              <TextField id="costo" label="Monto $" variant="outlined" style={{width:'100%'}}               
                value={costo}
                onChange={(event) => setCosto(event.target.value)}
                error={costoError}
              />
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}