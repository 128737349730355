import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DialogoAgregar from './Home/DialogoAgregar'
import DialogoEditar from './Home/DialogoEditar'
import DialogoEliminar from './Home/DialogoEliminar'
import PrintIcon from '@mui/icons-material/Print';

export default function HomeCliente(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [actividades, setActividades] = React.useState([]); 
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [dialogoAgregar, setDialogoAgregar] = React.useState(false);
  const [dialogoEditar, setDialogoEditar] = React.useState(false);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);
  const [orden, setOrden] = React.useState('fecha');
  const [orden2, setOrden2] = React.useState(1);
  const [filtroPersona, setFiltroPersona] = React.useState(0); 
  const [arrayPersonas, setArrayPersonas] = React.useState([]); 
  const [filtroSemana, setFiltroSemana] = React.useState(0); 
  const [arraySemanas, setArraySemanas] = React.useState([]);
  const [filtroProyecto, setFiltroProyecto] = React.useState(0); 
  const [arrayProyectos, setArrayProyectos] = React.useState([]);  
  const [width, setWidth] = React.useState(window.innerWidth * 0.8);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("0")) {                    
        navigate('/');
      }
    }
    props.setPagina('homeCliente'); cookies.set('sethmon_pagina', 'homeCliente');
  }, []);

  useEffect(() => {    
    getDatos('fecha',1);
    getPersonas();
    getSemanas();
    getProyectos()
  }, [props.userArray.id]);

  function getDatos(i,i2){
    axios.post(props.ruta + "backend/home/get_datos.php",{
      userId: props.userArray.id,
      arrayPermisos: props.userArray.permisos,
      orden: i,
      orden2: i2,
      filtroPersona: filtroPersona,
      filtroSemana: filtroSemana,
      filtroProyecto: filtroProyecto
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setActividades(data);
      }
    })
  }

  function getPersonas(){
    axios.post(props.ruta + "backend/home/get_filtro_personas.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayPersonas(data);
      }
    })
  }

  function getSemanas(){
    axios.post(props.ruta + "backend/home/get_filtro_semanas.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArraySemanas(data);
      }
    })
  }

  function getProyectos(){
    axios.post(props.ruta + "backend/home/get_filtro_proyectos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayProyectos(data);
      }
    })
  }

  const renderBotonAgregar = () => {
    if (props.checkPermisos("1") || props.checkPermisos("11")) {      
      return(
        <Grid xs={12} md={2}>  
          <Button variant="outlined" startIcon={<AddIcon />} style={{borderColor: '#082342', color: '#082342'}} onClick={() => setDialogoAgregar(true)}>
            Agregar
          </Button>
        </Grid>
      )
    }
  }
  
  const renderDialogoAgregar = () =>{    
    if(dialogoAgregar === true){
      return(
        <DialogoAgregar 
          {...props}     
          open = {dialogoAgregar}
          setOpen = {setDialogoAgregar}
          getDatos = {getDatos}
        />
      )
    }
  }

  const renderBotonEditar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("12")) {      
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
          <EditIcon />
        </IconButton>
      )
    }
  }

  function editar(i){
    setArrayDatos(i);
    setDialogoEditar(true);
  }
  
  const renderDialogoEditar = () =>{    
    if(dialogoEditar === true){
      return(
        <DialogoEditar
          {...props}     
          open = {dialogoEditar}
          setOpen = {setDialogoEditar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonEliminar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("13")) {      
      return(         
        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  function ordenar(i){

    var a = orden;

    if(i === 'fecha' || i === 'responsable' || i === 'semana'){
      
      if(i === orden){
        if(orden2 === 1){
          setOrden2(2);
          a = 2;
        } else {
          setOrden2(1);
          a = 1;
        }
      } else {
        setOrden(i);
        a = 1;
      }

      getDatos(i,a);
    }
  }
  
  const renderIconoFecha = () =>{    
    if(orden === 'fecha'){
      if(orden2 === 1){
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandMoreIcon />
          </IconButton>
        )
      } else {
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandLessIcon />
          </IconButton>
        )
      }
    }
  }

  const renderIconoResp = () =>{ 
    if(orden === 'responsable'){
      if(orden2 === 1){
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandMoreIcon />
          </IconButton>
        )
      } else {
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandLessIcon />
          </IconButton>
        )
      }
    }
  }

  const renderIconoKw = () =>{ 
    if(orden === 'semana'){
      if(orden2 === 1){
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandMoreIcon />
          </IconButton>
        )
      } else {
        return(         
          <IconButton style={{color:'#ffffff'}} >
            <ExpandLessIcon />
          </IconButton>
        )
      }
    }
  }

  const renderFiltros = () =>{
    if (props.checkPermisos("1") || props.checkPermisos("25")) {    
      return(
        <>
          <Grid item xs={12} md={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Ver personas"
              value={filtroPersona}
              style={{width: '100%'}}
              onChange={(event) => setFiltroPersona(event.target.value)}
            >
              <MenuItem key={0} value={''}> Sin filtro </MenuItem>
              {arrayPersonas.map((option) => (
                <MenuItem key={option.responsableId} value={option.responsableId}>
                  {option.responsable}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Ver sememana"
              value={filtroSemana}
              style={{width: '100%'}}
              onChange={(event) => setFiltroSemana(event.target.value)}
            >
              <MenuItem key={0} value={''}> Sin filtro </MenuItem>
              {arraySemanas.map((option) => (
                <MenuItem key={option.semana} value={option.semana}>
                  {'Semana ' + option.semana}
                </MenuItem>
              ))}
            </TextField>
          </Grid> 
          <Grid item xs={12} md={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Ver proyectos"
              value={filtroProyecto}
              style={{width: '100%'}}
              onChange={(event) => setFiltroProyecto(event.target.value)}
            >              
              <MenuItem key={0} value={''}> Sin filtro </MenuItem>
              {arrayProyectos.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.proyecto}
                </MenuItem>
              ))}
            </TextField>
          </Grid> 
          <Grid item xs={4} md={1}> 
            <Button onClick={generar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
              Generar
            </Button>
          </Grid>
          <Grid item xs={8} md={2}> 
            <Button 
              onClick={exportarExcel} 
              variant="outlined" 
              color="success"
              startIcon={<PrintIcon />}
            >
              Exportar a Excel
            </Button>
          </Grid>
        </> 
      )
    }
  }

  function exportarExcel(){
    var pag = props.ruta + "backend/home/crear_excel.php?i=" + props.userArray.id + "&i2=" + filtroPersona + "&i3=" + filtroSemana + "&i4=" + filtroProyecto;

    window.open(pag, "_blank");
  }

  function generar(){
    getDatos(orden,orden2);
  }

  const renderDatos = () => {
    if(width >= 400){
      return(
        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer sx={{ maxHeight: 600}}>
              <Table
                sx={{ minWidth: '90%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '7%'}}
                    >

                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => ordenar('responsable')}
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'pointer', width: '10%'}}
                    >
                      Responsable {renderIconoResp()}
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => ordenar('semana')}
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'pointer', width: '8%'}}
                    >
                      Semana {renderIconoKw()}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '5%'}}
                    >
                      Domingo
                    </TableCell>
                    <TableCell
                      align='center'
                      onClick={() => ordenar('fecha')}
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'pointer', width: '10%'}}
                    >
                      Fecha {renderIconoFecha()}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '5%'}}
                    >
                      Horas
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '10%'}}
                    >
                      Lugar
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '15%'}}
                    >
                      Proyecto
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '10%'}}
                    >
                      Actividad
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '25%'}}
                    >
                      Descripción del trabajo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actividades.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                        style={{
                          cursor: 'default',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <TableCell  component="th" scope="row" padding="none" align='center'>
                          {renderBotonEditar(row)}
                          {renderBotonEliminar(row)}
                        </TableCell>
                        <TableCell align="center">{row.responsable}</TableCell>
                        <TableCell align="center">{row.semana}</TableCell>
                        <TableCell align="center">{row.domingoFormato}</TableCell>
                        <TableCell align="center">{row.fechaFormato}</TableCell>
                        <TableCell align="center">{row.horas}</TableCell>
                        <TableCell align="center">{row.lugar}</TableCell>
                        <TableCell align="center">{row.proyecto}</TableCell>
                        <TableCell align="center">{row.actividad}</TableCell>
                        <TableCell align="left">{row.descripcion}</TableCell>
                      </TableRow>
                    );
                  })}                  
                </TableBody>
              </Table>
            </TableContainer>          
          </Paper>
        </Grid> 
      )
    } else {
      return(
        <>
          {actividades.map((row, index) => {    
            return(        
              <Card sx={{ maxWidth: 345, minWidth: 345 }} style={{margin: 5}}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: '#082342' }} aria-label="recipe">
                      {row.responsableLetras}
                    </Avatar>
                  }
                  title={row.responsable}
                  subheader={row.fechaFormato + ' KW ' + row.semana}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Horas: {row.horas} |  Lugar: {row.lugar}
                    <br />
                    Proyecto: {row.proyecto}
                    <br />
                    Actividad: {row.actividad}
                    <br />
                    Descripción: {row.descripcion}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  {renderBotonEditar(row)}
                  {renderBotonEliminar(row)}
                </CardActions>          
              </Card>
            )
          })}
        </>
      )
    }
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          Inicio cliente
          </Typography>
        </Grid>

        {renderBotonAgregar()}

        {renderFiltros()}

        {renderDatos()}

           
      </Grid>
      
      {renderDialogoAgregar()}
      {renderDialogoEditar()}
      {renderDialogoEliminar()}

    </Box>
  );
}