import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

import DialogoAgregar from './Alimentos/DialogoAgregar'
import DialogoEditar from './Alimentos/DialogoEditar'
import DialogoEliminar from './Alimentos/DialogoEliminar'

const headCells = [
  {
    id: '',
    label: '',
    width: '5.26%',
  },
  {
    id: 'alimento',
    label: 'Alimento',
    width: '5.26%',
  },
  {
    id: 'descripcion',
    label: 'Descripcion',
    width: '5.26%',
  },
  {
    id: 'tipo',
    label: 'Tipo',
    width: '5.26%',
  },
  {
    id: 'cant_sug',
    label: 'Cantidad sugerida',
    width: '5.26%',
  },
  {
    id: 'unidad',
    label: 'Unidad',
    width: '5.26%',
  },
  {
    id: 'peso_bruto',
    label: 'Peso bruto redondeado (g)',
    width: '5.26%',
  },
  {
    id: 'peso_neto',
    label: 'Peso neto (g)',
    width: '5.26%',
  },
  {
    id: 'energia',
    label: 'Energía (kcal)',
    width: '5.26%',
  },
  {
    id: 'prote',
    label: 'Proteína (g)',
    width: '5.26%',
  },
  {
    id: 'lipidos',
    label: 'Lípidos (g)',
    width: '5.26%',
  },
  {
    id: 'hidratos_carb',
    label: 'Hidratos de carbono (g)',
    width: '5.26%',
  },
  {
    id: 'fibra',
    label: 'Fibra (g)',
    width: '5.26%',
  },
  {
    id: 'vit_a',
    label: 'Vitamina A (mg RE)',
    width: '5.26%',
  },
  {
    id: 'acido_asc',
    label: 'Acido ascórbico (mg)',
    width: '5.26%',
  },
  {
    id: 'acido_fol',
    label: 'Acido Fólico (mg)',
    width: '5.26%',
  },
  {
    id: 'hem',
    label: 'Hierro NO HEM (mg)',
    width: '5.26%',
  },
  {
    id: 'potasio',
    label: 'Potasio (mg)',
    width: '5.26%',
  },
  {
    id: 'ind_gli',
    label: 'Índice glicémico',
    width: '5.26%',
  },
  {
    id: 'carga_gli',
    label: 'Carga glicémica',
    width: '5.26%',
  }
];

export default function Alimentos(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [buscar, setBuscar] = React.useState('');
  const [datos, setDatos] = React.useState([]); 
  const [arrayDatos, setArrayDatos] = React.useState([]); 
  const [dialogoAgregar, setDialogoAgregar] = React.useState(false);
  const [dialogoEditar, setDialogoEditar] = React.useState(false);
  const [dialogoEliminar, setDialogoEliminar] = React.useState(false);
  const [msgVacio, setMsgVacio] = React.useState(false);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("15")) {                    
        navigate('/');
      }
    }
    props.setPagina('alimentos'); cookies.set('sethmon_pagina', 'alimentos');
  }, []);

  useEffect(() => {    
    getDatos();
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/alimentos/get_datos.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setDatos(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  function getDatos2(a){
    axios.post(props.ruta + "backend/alimentos/get_datos_afectados.php",{
      userId: props.userArray.id,
      alimento: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setDatos(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  function busqueda(){
    axios.post(props.ruta + "backend/alimentos/get_buscar.php",{
      userId: props.userArray.id,
      buscar: buscar
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setDatos(data);
        setMsgVacio(false);
      } else if(data[0].error === 1) {
        setMsgVacio(true);
      }
    })
  }

  const renderBotonAgregar = () => {
    if (props.checkPermisos("1") || props.checkPermisos("16")) {      
      return(
        <Grid xs={1} md={2}>  
          <Button variant="contained" startIcon={<AddIcon />} style={{backgroundColor: '#082342', color: '#ffffff'}} onClick={() => setDialogoAgregar(true)}>
            Agregar
          </Button>
        </Grid>
      )
    }
  }
  
  const renderDialogoAgregar = () =>{    
    if(dialogoAgregar === true){
      return(
        <DialogoAgregar 
          {...props}     
          open = {dialogoAgregar}
          setBuscar = {setBuscar}
          setOpen = {setDialogoAgregar}
          getDatos2 = {getDatos2}
        />
      )
    }
  }

  const renderBotonEditar = (datos) => {    
    if ((datos.id_coach !== 1 && datos.id_coach !== '1') || props.checkPermisos("1") || (datos.id_coach !== 1 && datos.id_coach !== '1'  && props.checkPermisos("17")) ) {
      return(         
        <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
          <EditIcon />
        </IconButton>
      )
    }
  }

  function editar(i){
    setArrayDatos(i);
    setDialogoEditar(true);
  }
  
  const renderDialogoEditar = () =>{    
    if(dialogoEditar === true){
      return(
        <DialogoEditar
          {...props}     
          open = {dialogoEditar}
          setBuscar = {setBuscar}
          setOpen = {setDialogoEditar}
          getDatos2 = {getDatos2}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderBotonEliminar = (datos) => {
    if ((datos.id_coach !== 1 && datos.id_coach !== '1') || props.checkPermisos("1") || (datos.id_coach !== 1 && datos.id_coach !== '1'  && props.checkPermisos("18")) ) {
      return(         
        <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  }

  function eliminar(i){
    setArrayDatos(i);
    setDialogoEliminar(true);
  }
  
  const renderDialogoEliminar = () =>{    
    if(dialogoEliminar === true){
      return(
        <DialogoEliminar
          {...props}     
          open = {dialogoEliminar}
          setOpen = {setDialogoEliminar}
          getDatos = {getDatos}
          arrayDatos = {arrayDatos}
        />
      )
    }
  }

  const renderTableBody = () =>{    
    if(msgVacio === false){
      return(
        <TableBody>
          {datos.map((row, index) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                style={{cursor: 'default', backgroundColor: row.estado === 'B'? '#ffcdd2':'' }}
              >
                <TableCell  component="th" scope="row" padding="none" align='center'>
                  {renderBotonEditar(row)}
                  {renderBotonEliminar(row)}
                </TableCell>
                <TableCell align="center">{row.alimento}</TableCell>
                <TableCell align="center">{row.descripcion}</TableCell>
                <TableCell align="center">{row.tipo}</TableCell>
                <TableCell align="center">{row.cant_sug}</TableCell>
                
                <TableCell align="center">{row.unidad}</TableCell>
                <TableCell align="center">{row.peso_bruto}</TableCell>
                <TableCell align="center">{row.peso_neto}</TableCell>
                <TableCell align="center">{row.energia}</TableCell>
                <TableCell align="center">{row.prote}</TableCell>
                <TableCell align="center">{row.lipidos}</TableCell>
                <TableCell align="center">{row.hidratos_carb}</TableCell>
                <TableCell align="center">{row.fibra}</TableCell>
                <TableCell align="center">{row.vit_a}</TableCell>
                <TableCell align="center">{row.acido_asc}</TableCell>
                <TableCell align="center">{row.acido_fol}</TableCell>
                <TableCell align="center">{row.hem}</TableCell>
                <TableCell align="center">{row.potasio}</TableCell>
                <TableCell align="center">{row.ind_gli}</TableCell>
                <TableCell align="center">{row.carga_gli}</TableCell>
              </TableRow>
            );
          })}                  
        </TableBody>
      )
    } else {
      return(         
        <TableRow
          hover
          tabIndex={-1}
          key={1}
          style={{cursor: 'default'}}
        >
          <TableCell align="center" colSpan={14}>No se tienen registros</TableCell>
        </TableRow>
      )
    }
  } 
  
  return (
    <Box sx={{ minHeight: '100vh'}} style={{backgroundColor: props.colorTres}} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#ffffff', textAlign: 'center'}}>
          Alimentos
          </Typography>
        </Grid>

        {renderBotonAgregar()}
        <Grid xs={4}>  
          <TextField id="buscar" label="Buscar" variant="outlined" style={{backgroundColor: '#ffffff', width:'100%'}}               
            value={buscar}
            onChange={(event) => setBuscar(event.target.value)}
          />
        </Grid>
        <Grid xs={7} md={6}>  
          <Button variant="contained" startIcon={<SearchIcon />} style={{backgroundColor: '#082342', color: '#ffffff'}} onClick={() => busqueda()}/>
        </Grid>

        <Grid xs={12}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: '99%' }}
                aria-labelledby="tableTitle"
                size='medium'
                order='asc'
                orderby='producto'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: headCell.width}}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {renderTableBody()}                
              </Table>
            </TableContainer>          
          </Paper>
        </Grid>    
      </Grid>
      
      {renderDialogoAgregar()}
      {renderDialogoEditar()}
      {renderDialogoEliminar()}

    </Box>
  );
}