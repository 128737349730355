import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [datos, setDatos] = React.useState([]); 
  const [alimento, setAlimento] = React.useState('');
  const [alimentoError, setAlimentoError] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [tipo, setTipo] = React.useState('');
  const [cantSug, setCantSug] = React.useState('');
  const [unidad, setUnidad] = React.useState('');
  const [pesoBruto, setPesoBruto] = React.useState('');
  const [pesoNeto, setPesoNeto] = React.useState('');
  const [energia, setEnergia] = React.useState('');
  const [prote, setProte] = React.useState('');
  const [lipidos, setLipidos] = React.useState('');
  const [hidratosCarb, setHidratosCarb] = React.useState('');
  const [fibra, setFibra] = React.useState('');
  const [vitA, setVitA] = React.useState('');
  const [acidoAsc, setAcidoAsc] = React.useState('');
  const [acidoFol, setAcidoFol] = React.useState('');
  const [hem, setHem] = React.useState('');
  const [potasio, setPotasio] = React.useState('');
  const [indGli, setIndGli] = React.useState('');
  const [cargaGli, setCargaGli] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    getDatos();
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/alimentos/get_datos_tipo.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setDatos(data);
      }
    })
  }

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (alimento === "") { setAlimentoError('error'); ban = 1; } else { setAlimentoError(''); }    

    if(ban === 0){
      axios.post(props.ruta + "backend/alimentos/agregar.php", {
          userId: props.userArray.id,
          alimento: alimento,
          descripcion: descripcion,
          tipo: tipo,
          cantSug: cantSug,
          unidad: unidad,
          pesoBruto: pesoBruto,
          pesoNeto: pesoNeto,
          energia: energia,
          prote: prote,
          lipidos: lipidos,
          hidratosCarb: hidratosCarb,
          fibra: fibra,
          vitA: vitA,
          acidoAsc: acidoAsc,
          acidoFol: acidoFol,
          hem: hem,
          potasio: potasio,
          indGli: indGli,
          cargaGli: cargaGli
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.setBuscar(alimento);
            props.getDatos2(alimento);
            props.setOpen(false);
          } else if(data[0].error === 3){
            props.setDialogNotificacionTipo('Alerta');
            props.setDialogNotificacionMensaje('La descripción ya existe');
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Costo $"');
    } else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Periodo"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='lg'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Agregar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={3}>
              <TextField id="alimento" label="Alimento" variant="outlined" style={{width:'100%'}}               
                value={alimento}
                onChange={(event) => setAlimento(event.target.value)}
                error={alimentoError}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField id="descripcion" label="Descripcion" variant="outlined" style={{width:'100%'}}               
                value={descripcion}
                onChange={(event) => setDescripcion(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField
                id="outlined-select-currency"
                select
                label="Tipo"
                value={tipo}
                style={{width: '100%'}}
                onChange={(event) => setTipo(event.target.value)}
              >
                {datos.map((row, index) => {
                  return (
                    <MenuItem key={row.id} value={row.nombre}>
                      {row.nombre}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="cantSug" label="Cantidad sugerida" variant="outlined" style={{width:'100%'}}               
                value={cantSug}
                onChange={(event) => setCantSug(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="unidad" label="Unidad" variant="outlined" style={{width:'100%'}}               
                value={unidad}
                onChange={(event) => setUnidad(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="pesoBruto" label="Peso bruto redondeado (g)" variant="outlined" style={{width:'100%'}}               
                value={pesoBruto}
                onChange={(event) => setPesoBruto(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="pesoNeto" label="Peso neto (g)" variant="outlined" style={{width:'100%'}}               
                value={pesoNeto}
                onChange={(event) => setPesoNeto(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="energia" label="Energía (kcal)" variant="outlined" style={{width:'100%'}}               
                value={energia}
                onChange={(event) => setEnergia(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="prote" label="Proteína (g)" variant="outlined" style={{width:'100%'}}               
                value={prote}
                onChange={(event) => setProte(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="lipidos" label="Lípidos (g)" variant="outlined" style={{width:'100%'}}               
                value={lipidos}
                onChange={(event) => setLipidos(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="hidratosCarb" label="Hidratos de carbono (g)" variant="outlined" style={{width:'100%'}}               
                value={hidratosCarb}
                onChange={(event) => setHidratosCarb(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="fibra" label="Fibra (g)" variant="outlined" style={{width:'100%'}}               
                value={fibra}
                onChange={(event) => setFibra(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="vitA" label="Vitamina A (mg RE)" variant="outlined" style={{width:'100%'}}               
                value={vitA}
                onChange={(event) => setVitA(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="acidoAsc" label="Acido ascórbico (mg)" variant="outlined" style={{width:'100%'}}               
                value={acidoAsc}
                onChange={(event) => setAcidoAsc(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="acidoFol" label="Acido Fólico (mg)" variant="outlined" style={{width:'100%'}}               
                value={acidoFol}
                onChange={(event) => setAcidoFol(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="hem" label="Hierro NO HEM (mg)" variant="outlined" style={{width:'100%'}}               
                value={hem}
                onChange={(event) => setHem(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="potasio" label="Potasio (mg)" variant="outlined" style={{width:'100%'}}               
                value={potasio}
                onChange={(event) => setPotasio(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="indGli" label="Índice glicémico" variant="outlined" style={{width:'100%'}}               
                value={indGli}
                onChange={(event) => setIndGli(event.target.value)}
              />
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField id="cargaGli" label="Carga glicémica" variant="outlined" style={{width:'100%'}}               
                value={cargaGli}
                onChange={(event) => setCargaGli(event.target.value)}
              />
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}