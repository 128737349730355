import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [nombre, setNombre] = React.useState('');
  const [nombreError, setNombreError] = React.useState('');
  const [costo, setCosto] = React.useState('');
  const [costoError, setCostoError] = React.useState('');
  const [periodo, setPeriodo] = React.useState('');
  const [periodoError, setPeriodoError] = React.useState('');
  const [tipo, setTipo] = React.useState('M');

  const handleClose = () => {
    props.setOpen(false)
  };  

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (nombre === "") { setNombreError('error'); ban = 1; } else { setNombreError(''); }
    if (costo === "") { setCostoError('error'); ban = 1; } else { setCostoError(''); }
    if (periodo === "") { setPeriodoError('error'); ban = 1; } else { setPeriodoError(''); }

    if(ban === 0){
      let resultado = parseFloat(costo);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - costo;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setCostoError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setCostoError('error');
        ban = 2;
      }
    }

    if(ban === 0){
      let resultado = parseFloat(periodo);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - periodo;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setCostoError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setPeriodoError('error');
        ban = 3;
      }
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/costos/agregar.php", {
          userId: props.userArray.id,
          nombre: nombre,
          costo: costo,
          periodo: periodo,
          tipo: tipo,
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.getDatos2(nombre);
            props.setOpen(false);
          } else if(data[0].error === 3){
            props.setDialogNotificacionTipo('Alerta');
            props.setDialogNotificacionMensaje('La descripción ya existe');
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Costo $"');
    } else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Periodo"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='lg'
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Agregar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={12} md={8}>
              <TextField id="descripcion" label="Descripcion" variant="outlined" style={{width:'100%'}}               
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                error={nombreError}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField id="costo" label="Costo $" variant="outlined" style={{width:'100%'}}               
                value={costo}
                onChange={(event) => setCosto(event.target.value)}
                error={costoError}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Tipo"
                value={tipo}
                style={{width: '100%'}}
                onChange={(event) => setTipo(event.target.value)}
              >
                <MenuItem key={0} value='D'>
                  Días
                </MenuItem>
                <MenuItem key={1} value='S'>
                  Semanal
                </MenuItem>
                <MenuItem key={1} value='Q'>
                  Quincenal
                </MenuItem>
                <MenuItem key={1} value='M'>
                  Mensual
                </MenuItem>
                <MenuItem key={2} value='A'>
                  Anual
                </MenuItem>
              </TextField>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField id="periodo" label="Periodo" variant="outlined" style={{width:'100%'}}               
                value={periodo}
                onChange={(event) => setPeriodo(event.target.value)}
                error={periodoError}
                helperText='Ingrese la cantidad de días, semanas, quincenas, meses o años según aplique.'
              />
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}