import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

export default function Home(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      } 
    } else {
      if(cookies.get('sethmon_tipo') === 2 || cookies.get('sethmon_tipo') === '2'){        
        navigate('/homeCliente');
      } else if(!props.checkPermisos("1") && !props.checkPermisos("3")) {                    
        navigate('/');
      } 
    }      
    props.setPagina('home'); cookies.set('sethmon_pagina', 'home');
  }, []);
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid xs={12}>  
          <Typography variant="h4" style={{color: '#082342', textAlign: 'center'}}>
          Inicio
          </Typography>
        </Grid>
           
      </Grid>

    </Box>
  );
}