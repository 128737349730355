import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import img404 from "../img/404.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function NoPage(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [tipos, setTipos] = React.useState([]);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    }
  }, []);
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
      <Grid container xs={4} alignItems="center" justifyContent="center">  
          <img
            src={img404}
            alt="Logo"
            style={{ marginTop: "15%", width: "90%", maxWidth: "600px" }}
          />
        </Grid>

        <Grid item xs={12}>  
          <Typography variant="h1" style={{color: '#082342', textAlign: 'center'}}>
            Página no encontrada
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
}