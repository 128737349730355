import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import Home from "./pages/Home";
import HomeCliente from "./pages/Home2";
import Alimentos from "./pages/Alimentos";
import Costos from "./pages/Costos";
import Clientes from "./pages/Clientes";
import Ejercicios from "./pages/Ejercicios";
import Usuarios from "./pages/Usuarios";
import NoPage from "./pages/NoPage";

const colorUno = "#964b00";
const colorDos = "#1ABC9C";
const colorTres = "#4c5356";
const colorCuatro = "#082342";
const version = '1.0.5 beta'

export default function App() {  
  // const [ruta] = React.useState("http://localhost/sethmon/src/"); // Desarrollo
  const [ruta] = React.useState("./"); // Productivo
  const [login, setLogin] = React.useState(false);
  const [userArray, setUserArray] = React.useState([]);
  const [pagina, setPagina] = React.useState('');
  const [dialogNotificacionOpen, setDialogNotificacionOpen] = React.useState(false);
  const [dialogNotificacionTipo, setDialogNotificacionTipo] = React.useState('');
  const [dialogNotificacionMensaje, setDialogNotificacionMensaje] = React.useState('');
  const [width] = React.useState(window.innerWidth * 0.8);

  function checkPermisos(permiso){
    if(login === true){
      if (userArray.permisos !== undefined) {
        var isInArray = userArray.permisos.includes(permiso);
        return isInArray;
      }
    }
  };  
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" 
          element={
            <Layout
            colorUno = {colorUno}
            colorDos = {colorDos}
            colorTres = {colorTres}
            colorCuatro = {colorCuatro}
            version = {version}
            dialogNotificacionOpen = {dialogNotificacionOpen}
            setDialogNotificacionOpen = {setDialogNotificacionOpen}
            dialogNotificacionTipo = {dialogNotificacionTipo}
            setDialogNotificacionTipo = {setDialogNotificacionTipo}
            dialogNotificacionMensaje = {dialogNotificacionMensaje}
            setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
            ruta={ruta}
            userArray={userArray}
            setUserArray={setUserArray}
            login={login}
            setLogin={setLogin}
            checkPermisos={checkPermisos}
            pagina={pagina}
            setPagina={setPagina}
            width={width}
            />
          }
        >
          <Route 
            index
            element={
              <Login 
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              colorCuatro = {colorCuatro}
              version = {version}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              width={width}
            />}
          />
          <Route path="home" 
            element={
              <Home
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              colorCuatro = {colorCuatro}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />
          <Route path="homeCliente" 
            element={
              <HomeCliente
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              colorCuatro = {colorCuatro}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />   
          <Route path="alimentos" 
            element={
              <Alimentos
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />    
          <Route path="costos" 
            element={
              <Costos
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />            
          <Route path="clientes" 
            element={
              <Clientes
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />  
          <Route path="ejercicios" 
            element={
              <Ejercicios
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />  
          <Route path="usuarios" 
            element={
              <Usuarios
              colorUno = {colorUno}
              colorDos = {colorDos}
              colorTres = {colorTres}
              dialogNotificacionOpen = {dialogNotificacionOpen}
              setDialogNotificacionOpen = {setDialogNotificacionOpen}
              dialogNotificacionTipo = {dialogNotificacionTipo}
              setDialogNotificacionTipo = {setDialogNotificacionTipo}
              dialogNotificacionMensaje = {dialogNotificacionMensaje}
              setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
              ruta={ruta}
              userArray={userArray}
              setUserArray={setUserArray}
              login={login}
              setLogin={setLogin}
              checkPermisos={checkPermisos}
              pagina={pagina}
              setPagina={setPagina}
              width={width}
              />
            } 
          />     
          <Route path="*" element={
            <NoPage  
            colorUno = {colorUno}
            colorDos = {colorDos}
            colorTres = {colorTres}           
            dialogNotificacionOpen = {dialogNotificacionOpen}
            setDialogNotificacionOpen = {setDialogNotificacionOpen}
            dialogNotificacionTipo = {dialogNotificacionTipo}
            setDialogNotificacionTipo = {setDialogNotificacionTipo}
            dialogNotificacionMensaje = {dialogNotificacionMensaje}
            setDialogNotificacionMensaje = {setDialogNotificacionMensaje}
            ruta={ruta}
            userArray={userArray}
            setUserArray={setUserArray}
            login={login}
            setLogin={setLogin}
            checkPermisos={checkPermisos}
            width={width}
            />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);