import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoAgregar(props) {
  const [datos, setDatos] = React.useState([]); 
  const [ejercicio, setEjercicio] = React.useState('');
  const [ejercicioError, setEjercicioError] = React.useState('');
  const [musculo, setMusculo] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {    
    getDatos();
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/ejercicios/get_datos_musculo.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setDatos(data);
      }
    })
  }

  function guardar(){        
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (ejercicio === "") { setEjercicioError('error'); ban = 1; } else { setEjercicioError(''); }    

    if(ban === 0){
      axios.post(props.ruta + "backend/ejercicios/agregar.php", {
          userId: props.userArray.id,
          ejercicio: ejercicio,
          musculo: musculo
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionOpen(false);
            props.setBuscar(ejercicio);
            props.getDatos2(ejercicio);
            props.setOpen(false);
          } else if(data[0].error === 3){
            props.setDialogNotificacionTipo('Alerta');
            props.setDialogNotificacionMensaje('La descripción ya existe');
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Costo $"');
    } else if(ban === 3){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Periodo"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}        
        fullWidth={true}
      >        
        <DialogTitle id="alert-dialog-title" style={{backgroundColor: '#082342', color: '#ffffff', textAlign: 'center'}}>
          <Typography variant="h5" style={{textAlign: 'center'}}>
            Agregar
          </Typography> 
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} justifyContent={'center'}>
            
            <Grid item xs={12}>
              <TextField id="ejercicio" label="Ejercicio" variant="outlined" style={{width:'100%'}}               
                value={ejercicio}
                onChange={(event) => setEjercicio(event.target.value)}
                error={ejercicioError}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-select-currency"
                select
                label="Musculo"
                value={musculo}
                style={{width: '100%'}}
                onChange={(event) => setMusculo(event.target.value)}
              >
                {datos.map((row, index) => {
                  return (
                    <MenuItem key={row.id} value={row.id}>
                      {row.nombre}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" style={{backgroundColor: '#a31545', color: '#ffffff'}}>
            Cancelar
          </Button>
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}